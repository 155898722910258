<template>
  <div class="home-box">
    <div>
      <div class="el-dialog-header-box">
        <div class="dialog-title-block"></div>
        <span class="dialog-title-text">用户提现管理</span>
      </div>
      <el-divider />
    </div>

    <!-- 查询区域 -->
    <div class="top-query">
      <div class="top-query-left">
        <div class="demo-input-suffix">
          审核状态：
          <el-select v-model="value1" placeholder="请选择" clearable>
            <el-option
              v-for="item in typeOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="demo-input-suffix">
          账户类型：
          <el-select v-model="value2" placeholder="请选择" clearable>
            <el-option
              v-for="item in isshenheOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <el-button type="primary" @click="search" icon="el-icon-search"
          >搜索</el-button
        >
        <!-- <el-button @click="add" type="success" icon="el-icon-plus">创建店</el-button> -->
      </div>

      <div class="top-query-right"></div>
    </div>

    <!-- table区域-begin -->
    <div class="table-box">
      <el-table
        :data="tableData.data"
        stripe
        style="width: 100%"
        v-loading="loading"
      >
        <el-table-column prop="id" label="ID" width="120"> </el-table-column>
        <el-table-column prop="money" label="提现金额" min-width="120">
        </el-table-column>
        <el-table-column prop="cost" label="手续费" min-width="120">
        </el-table-column>
        <el-table-column prop="actual_money" label="到账金额" min-width="120">
        </el-table-column>
        <el-table-column prop="type" label="账户类型" min-width="120">
          <template slot-scope="scope">
            <span>{{ typeObject[scope.row.type] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="realname" label="账户信息" min-width="120">
        </el-table-column>
        <el-table-column prop="isshenhe" label="审核状态" min-width="120">
          <template slot-scope="scope">
            <span>{{ isshenheOptions[scope.row.isshenhe] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" min-width="120">
        </el-table-column>
        <el-table-column prop="createdate" label="提现时间" min-width="120">
        </el-table-column>
      </el-table>
    </div>
    <!-- table区域-end -->

    <!-- 分页区域-begin -->
    <div>
      <el-pagination
        class="pagination"
        background
        layout="prev, pager, next"
        :total="tableData.total"
        :page-size="tableData.per_page"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <!-- 分页区域-end -->
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import axios from "axios";

export default({
  name: "platform",
  data() {
    return {
      input1: "",
      input2: "",
      tableData: {
        total: 10,
        per_page: 10,
      },
      value1: "",
      value2: "",
      typeObject: {},
      typeOptions: [],
      isshenheOptions: [],
      total: 0,
      loading: false,
      page: 1,

    };
  },
  computed: {
    ...mapState(["user", "setting"]),
  },
  mounted() {
    let user = JSON.parse(localStorage.user);
    this.token = user.token;
    // this.getautoshow()
    // this.getInfo()
    // this.getechart();
    this.getCashOutList();
    // setTimeout(() => {
    //   this.loading = false;
    // }, 1000);
  },
  methods: {
    ...mapMutations(["setuser", "setsetting"]),
    getechart() {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.$echarts.init(document.getElementById("chart"));
      // 绘制图表
      myChart.setOption({
        title: {
          text: "核销券数",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          // prettier-ignore
          data: ['00:00', '01:15', '02:30', '03:45', '05:00', '06:15', '07:30', '08:45', '10:00', '11:15', '12:30', '13:45', '15:00', '16:15', '17:30', '18:45', '20:00', '21:15', '22:30', '23:45'],
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value}",
          },
          axisPointer: {
            snap: true,
          },
        },
        series: [
          {
            smooth: true,
            data: [
              0,
              1,
              2,
              3,
              4,
              5,
              6,
              12,
              33,
              222,
              333,
              123,
              233,
              112,
              212,
              444,
              333,
              1,
              11,
              22,
            ],
            type: "line",
          },
        ],
      });
    },
    getInfo() {
      axios
        .post("http://shunyu.jnwww.top/admin.php?action=setting&method=store", {
          is_get: 1,
          token: this.token,
        })
        .then((res) => {
          console.log(res);
          if (res.data.msg == "登录已过期") {
            localStorage.removeItem("user");
            this.$router.replace("/");
            return;
          }
          let data = {
            store: res.data.data.store_name,
            logo: res.data.data.store_logo,
          };
          this.setsetting(data);
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    getautoshow() {
      axios
        .get(
          "http://shunyu.jnwww.top/admin.php?action=autoshow&method=ex_list",
          {
            params: {
              token: this.token,
            },
          }
        )
        .then((res) => {
          if (res.data.msg == "登录已过期") {
            localStorage.removeItem("user");
            this.$router.replace("/");
            return;
          }
          res.data.data.data.forEach((item) => {
            this.options.push({
              label: item.exhibition_title,
              value: item.exhibition_id,
            });
            this.value = res.data.data.data[0].exhibition_id;
            this.getList();
          });
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    getList() {
      axios
        .post(
          "http://shunyu.jnwww.top/admin.php?action=autoshow&method=tk_list",
          {
            exhibition_ids: this.value,
            begin_time: this.date[0],
            end_time: this.date[1],
            page_idx: this.page,
            page_size: 10,
            token: this.token,
          }
        )
        .then((res) => {
          this.tableData = res.data.data.data;
          if (res.data.data.data.length == 0) {
            this.empty = this.page;
            this.$message.error("已无数据");
          } else {
            this.empty = 10000000000000000;
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    search() {
      console.log("查询");
      let data = {
        page: this.page,
      };
      this.getCashOutList(data);
    },
    add() {
      console.log("新增");
    },
    handleEdit() {
      console.log("编辑");
    },
    handleDelete() {
      console.log("删除");
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.search();
    },
    // 获取提现列表
    getCashOutList(data) {
      this.loading = true;
      this.$api
        .cashOutList({ ...data })
        .then((res) => {
          if (res.code == "success") {
            this.tableData = res.data.list;
            this.typeObject = res.data.type;
            this.isshenheOptions = res.data.isshenhe;
            this.typeOptions = Object.values(res.data.type);
          } else {
            this.$message.error(res.msg);
          }
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err.msg);
          this.loading = false;
        });
    },
  },
});
</script>

<style scoped lang="less">
@import "../assets/less/easyElement.less";
</style>
